.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* The hero image */
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./compsci.jpeg");

  /* Set a specific height */
  height: 50%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: auto;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  top: 50%;
  left: 50%;
  color: white;
}

.Image {
  position: absolute;
  float: left;
  padding: 1%;
  z-index: 1;
}

.Site-image {
  position: absolute;
  height: 150%;
  pointer-events: none;
}

#sroot {
  height: 100%;
}